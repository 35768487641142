/* eslint-disable camelcase */
import CheckboxInput from '../../../../../components/organisms/checkboxInput/CheckboxInput'
import NumberInput from '../../../../../components/organisms/numberInput/NumberInput'
import ObjectInput from '../../../../../components/organisms/objectInput/ObjectInput'
import JobBoards from './JobBoards'
import useAllStates from './hooksAndStates'

interface Iprops {
  publisher: string
}
export const Schalttool: React.FC<Iprops> = ({ publisher }) => {
  const {
    uniqueId,
    clientId,
    setClientId,
    expires,
    setExpires,
    autoPublish,
    setAutoPublish,
    jopago,
    setJopago,
    freeBundle,
    setFreeBundle,
    rekruter,
    setRekruter,
    onlyjobs,
    setOnlyjobs,
    indeed_campaign,
    setIndeed_campaign,
    setExtraInputs,
    setFreeBundleJobBoards,
    kleinanzeigen_onlyjobs,
    setKleinanzeigen_onlyjobs,
    ebay_kleinanzeigen,
    setEbay_kleinanzeigen,
    xing,
    setXing,
  } = useAllStates()
  if (publisher === 'schalttool') {
    return (
      <div className='publisherSelected '>
        <div className='SchalttoolSelected inputContainer'>
          <NumberInput
            label='Client ID'
            value={clientId}
            onChange={(e) => setClientId(e.currentTarget.valueAsNumber)}
            uniqueId={uniqueId}
            idAttr='clientId'
          />
          <NumberInput
            label='Expires (Days)'
            value={expires}
            onChange={(e) => setExpires(e.currentTarget.valueAsNumber)}
            max={365}
            min={1}
            uniqueId={uniqueId}
            idAttr='expires'
          />
          <div className='checkBoxes'>
            <CheckboxInput
              checked={autoPublish}
              onChange={setAutoPublish}
              label='Autopublish'
              uniqueId={uniqueId}
            />
          </div>
          <div></div>
          <h3>Context</h3>
          <div></div>
          <div className=' inputContainer checkBoxes'>
            <h4>Apis</h4>
            <div></div>
            <CheckboxInput
              checked={ebay_kleinanzeigen}
              onChange={setEbay_kleinanzeigen}
              label='Kleinanzeigen.de'
              uniqueId={uniqueId}
            />
            <CheckboxInput
              checked={xing}
              onChange={setXing}
              label='onlyfy (Xing)'
              uniqueId={uniqueId}
            />
            <CheckboxInput
              checked={kleinanzeigen_onlyjobs}
              onChange={setKleinanzeigen_onlyjobs}
              label='Kleinanzeigen.de (OnlyJobs)'
              uniqueId={uniqueId}
            />
            <CheckboxInput
              checked={jopago}
              onChange={setJopago}
              label='Jopago'
              uniqueId={uniqueId}
            />
            <CheckboxInput
              checked={freeBundle}
              onChange={setFreeBundle}
              label='FreeBundle'
              uniqueId={uniqueId}
            />
            <CheckboxInput
              checked={rekruter}
              onChange={setRekruter}
              label='Rekruter'
              uniqueId={uniqueId}
            />
            <CheckboxInput
              checked={onlyjobs}
              onChange={setOnlyjobs}
              label='OnlyJobs'
              uniqueId={uniqueId}
            />
            <CheckboxInput
              checked={indeed_campaign}
              onChange={setIndeed_campaign}
              label='Indeed Campaign'
              uniqueId={uniqueId}
            />
          </div>
          <div>{freeBundle && <JobBoards setFreeBundleJobBoards={setFreeBundleJobBoards} />}</div>{' '}
        </div>
        <ObjectInput defaultValue={'{}'} onChange={setExtraInputs} uniqueId={uniqueId} />
      </div>
    )
  } else {
    return null
  }
}
